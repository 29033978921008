import React, { createContext, useState } from "react"

const defaultVal = {
  lang: "en",
  isDark: "light",
  setIsDark: () => {}
}

export const GlobalContext = createContext(defaultVal);

const GlobalProvider = ({children, lang}) => {
  const [isDark , setIsDark] = useState(defaultVal.isDark)
  return (
    <GlobalContext.Provider value={{
      lang,
      isDark,
      setIsDark
    }}>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalProvider