/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import "survey-react/modern.css"
const React = require("react")

const { StylesManager } = require("survey-react")
const GlobalProvider = require("./src/components/GlobalProvider").default

// StylesManager.applyTheme("modern");

exports.onClientEntry = () => {
  StylesManager.applyTheme("modern");
}

exports.shouldUpdateScroll = ({
                                routerProps: { location },
                                getSavedScrollPosition,
                              }) => {
  const { pathname } = location
  // list of routes for the scroll-to-top-hook`
  const scrollToTopRoutes = [`/`, `/contact`, `/service`, `/promo`, `/privacy-policy`]
  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    document.querySelector("#gatsby-focus-wrapper").scroll(0,0)
  }

  return false
}

exports.wrapRootElement = ({ element }) => {
  return (
    <GlobalProvider>
      {element}
    </GlobalProvider>
  )
}

// const loadableReady = require('@loadable/component').loadableReady;
//
// exports.replaceHydrateFunction = () => {
//   return (element, container, callback) => {
//     loadableReady(() => {
//       ReactDOM.render(element, container, callback);
//     });
//   };
// };
